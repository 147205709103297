#resumePage {
    background-color: white;
    color: black;
}
#resumeImage {
    justify-content: center; /* Centers horizontally */
    align-items: center;    /* Centers vertically */
    width: 50%;            /* Container to take up full width */
    height: 50%;
}

