.App {
    text-align: center;
}

#intro {
    font-family: Consolas, serif;
}

#bar2 {
    background-color: aqua;
}

#butt {
    background-color: black;
}

@keyframes splick {
    from {border-color: black}
    to {border-color: white}
}
#but:hover {
    background-color: white;
    animation-name: splick;
    animation-duration: 4s;
}

#butt {
    color: white;
    border-width: 2px;
    border-style: solid;
    border-radius: 20px;
    border-color: black;
    font-family: Consolas, serif;
    stroke-dasharray: 4;
}

#butt:hover {
    animation-name: splick;
    animation-duration: 1s;
    border-color: white;
}
